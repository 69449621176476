import React from 'react'
import PropTypes from 'prop-types'

import './styles.scss'

const CardsWithText = ({ children }) => (
  <div className="text-lg privacy__cards-with-text">{children}</div>
)
CardsWithText.propTypes = {
  children: PropTypes.node.isRequired,
}

export default CardsWithText
