import React from 'react'
import IllustrationFiveOne from 'assets/svg/illustration-5-1.svg'
import IllustrationFiveTwo from 'assets/svg/illustration-5-2.svg'
import IllustrationLike from 'assets/svg/joinha.svg'
import IllustrationFiveFive from 'assets/svg/illustration-5-5.svg'
import IllustrationFiveSix from 'assets/svg/illustration-5-6.svg'
import IllustrationFiveSeven from 'assets/svg/illustration-5-7.svg'
import IllustrationFiveEight from 'assets/svg/illustration-5-8.svg'
import IllustrationFiveNine from 'assets/svg/illustration-5-9.svg'
import IllustrationFiveTen from 'assets/svg/illustration-5-10.svg'
import IllustrationPhone from 'assets/svg/phone-illustration.svg'
import CardText from '../../components/CardText'
import SectionTitle from '../../components/SectionTitle'
import SubSectionTitle from '../../components/SubSectionTitle'
import './styles.scss'
import InfoBlock from '../../components/InfoBlock'

const WhyCollectYourData = () => (
  <section id="capitulo-5" className="why-collect-your-data">
    <div className="container">
      <div className="columns why-collect-your-data__chapter-five">
        <div className="column why-collect-your-data__chapter-five--left is-two-fifths">
          <SectionTitle>
            5. Por que coletamos
            <br /> os seus dados?
          </SectionTitle>
        </div>
        <div className="column why-collect-your-data__chapter-five--right">
          <p className="text-xl font-normal">
            Saiba quais são as finalidades de tratamento dos seus dados pessoais relacionadas aos
            serviços da Plataforma.
          </p>
        </div>
      </div>
      <div className="column why-collect-your-data__chapter-five-one-top">
        <SubSectionTitle>5.1 Para prover nossos serviços</SubSectionTitle>
        <p className="text-grey-2">
          Podemos utilizar os dados coletados para{' '}
          <strong>
            prover, manter, melhorar e personalizar nossos produtos e serviços destinados a você,
          </strong>{' '}
          existentes ou a serem criados.
        </p>
      </div>
    </div>
    <div className="container">
      <div className="columns why-collect-your-data__chapter-five-one-bottom">
        <div className="column is-3 why-collect-your-data__chapter-five-one-bottom--left">
          <h4 className="text-grey-1">Esse tratamento inclui a utilização dos dados para:</h4>
          <div className="cards-chapter-five-one">
            <CardText text="criar e atualizar a sua conta" />
            <CardText text="permitir o preparo e a entrega dos pedidos pelos estabelecimentos parceiros" />
            <CardText text="personalizar a sua conta e preferências" />
            <CardText text="prover operações internas necessárias." />
          </div>
        </div>
        <div className="column is-3 why-collect-your-data__chapter-five-one-bottom--middle">
          <h4 className="text-grey-1">
            Também poderemos usar seus dados pessoais para finalidades internas, tais como:
          </h4>
          <div className="cards-chapter-five-one">
            <CardText text="auditoria" />
            <CardText text="análise de dados e pesquisas para aprimorar os produtos e serviços" />
            <CardText text="comunicações" />
            <CardText text="geração de análises estatísticas (isto inclui tendências de consumo)." />
          </div>
        </div>
        <div className="column why-collect-your-data__chapter-five-one-bottom--right">
          <IllustrationFiveOne />
        </div>
      </div>
    </div>

    <div className="container">
      <div className="columns why-collect-your-data__chapter-five-two">
        <div className="column why-collect-your-data__chapter-five-two--left">
          <IllustrationFiveTwo />
        </div>
        <div className="column why-collect-your-data__chapter-five-two--right">
          <SubSectionTitle>5.2 Para você avaliar restaurantes</SubSectionTitle>
          <p className="paragraph-five-two">
            Ao enviar comentários e feedback com relação à Plataforma e os serviços de delivery dos
            nossos parceiros, você nos autoriza a publicar e utilizar tais comentários e feedbacks
            na Plataforma, bem como a analisar, processar e tratar esse feedback de forma isolada ou
            agregada.
          </p>
          <InfoBlock className="left-text-block">
            <IllustrationLike />
            <p>Para tanto, apenas identificamos você através de seu nome de cadastro.</p>
          </InfoBlock>
        </div>
      </div>
      <div className="columns why-collect-your-data__chapter-five-three-four">
        <div className="column chapter-five--three">
          <SubSectionTitle>5.3 Para te enviar comunicações importantes</SubSectionTitle>
          <p className="text-grey-2">
            Periodicamente, poderemos usar os seus dados pessoais para enviar avisos e notificações
            importantes, como comunicados sobre{' '}
            <strong>compras, alterações em prazos, condições e políticas.</strong>
          </p>
          <InfoBlock className="five-three-text-block">
            <p className="text-sm">
              Como essas informações são importantes para a sua interação com o iFood,{' '}
              <strong>você não poderá optar por não receber esse tipo de comunicação,</strong> já
              que são inerentes ao uso do serviço.
            </p>
          </InfoBlock>
        </div>
        <div className="column chapter-five--four">
          <SubSectionTitle>5.4 Para te oferecer suporte</SubSectionTitle>
          <p className="text-grey-2">
            Também teremos acesso às suas informações quando você solicitar suporte em relação a
            pedidos ou entregas por meio da aba “Ajuda” na Plataforma.
          </p>
          <p className="text-grey-2">
            <strong>Neste caso, poderemos utilizar seus dados cadastrais para:</strong>
          </p>
          <p className="text-grey-2">
            • confirmar que a solicitação foi feita por você,
            <br />
            • direcionar as suas dúvidas para o atendente de suporte,
            <br />
            • investigar e direcionar seu problema,
            <br />
            • monitorar e melhorar nossos procedimentos de suporte.
            <br />
          </p>
        </div>
      </div>
      <div className="columns why-collect-your-data__chapter-five-five">
        <div className="column why-collect-your-data__chapter-five-five--left">
          <SubSectionTitle>5.5. Para gerenciar pagamentos feitos online</SubSectionTitle>
          <p className="text-grey-2">
            O iFood se preocupa com a segurança da informação e a conformidade com a legislação
            financeira.{' '}
            <strong>
              Armazenamos suas informações financeiras apenas de forma anonimizada, o que nos impede
              de ter acesso a seus dados financeiros completos.
            </strong>
          </p>
          <p className="text-grey-2">
            Além disso,{' '}
            <strong>somente processamos seus dados para a finalidade especificada,</strong>
            qual seja, a de permitir que uma transação seja efetuada por meio online e com cobrança
            direcionada a você em contraprestação aos serviços prestados.
          </p>
          <InfoBlock className="five-five-text-block">
            <p className="text-white text-sm font-bold">Não se preocupe</p>
            <p className="text-white text-sm">
              O iFood nunca processará as suas informações financeiras e/ou bancárias de forma a
              desrespeitar as normas regulatórias aplicáveis.
            </p>
          </InfoBlock>
        </div>
        <div className="column why-collect-your-data__chapter-five-five--right">
          <IllustrationFiveFive />
        </div>
      </div>
      <div className="columns why-collect-your-data__chapter-five-six">
        <div className="column why-collect-your-data__chapter-five-six--left">
          <IllustrationFiveSix />
        </div>
        <div className="column why-collect-your-data__chapter-five-six--right">
          <SubSectionTitle>5.6 Para segurança e prevenção à fraude</SubSectionTitle>
          <p className="text-grey-2">
            Nós também podemos utilizar seus dados pessoais para{' '}
            <strong>
              aprimorar nossos mecanismos de segurança e melhorar os serviços e as ofertas do iFood
              destinadas a você.
            </strong>{' '}
            Dessa forma, podemos analisar e solucionar problemas técnicos, bem como identificar e
            coibir fraudes na utilização do nosso serviço.
          </p>
          <InfoBlock className="five-six-right-text-block">
            <div>
              <p className="text-grey-2">
                Se você deseja confirmar as compras e autorizar os pagamentos no aplicativo, de
                forma preventiva, você pode ativar a funcionalidade de Face ID e Touch ID.{' '}
                <u>Esse mecanismo opcional está disponível somente para o aplicativo iOS</u>, sendo
                que{' '}
                <strong>
                  os dados são tratados localmente diretamente pelo próprio sistema operacional do
                  seu dispositivo e o iFood não tem acesso a esses dados.
                </strong>
              </p>
              <p className="text-grey-2">
                <strong>
                  O papel do iFood, nesse caso, é só solicitar que o próprio dispositivo valide se o
                  dado biométrico corresponde à identidade do usuário.
                </strong>
              </p>
            </div>
          </InfoBlock>
        </div>
      </div>
      <div className="columns why-collect-your-data__chapter-five-seven">
        <div className="column why-collect-your-data__chapter-five-seven--left">
          <SubSectionTitle>5.7. Para personalizar as campanhas de publicidade</SubSectionTitle>
          <p className="text-grey-2">
            Se você participar de um concurso, competição ou qualquer outra modalidade de promoção,
            <strong>
              {' '}
                poderemos usar as informações que você fornecer para administrar esses programas.
            </strong>
          </p>
          <p className="text-grey-2">
            Nós poderemos usar seus dados pessoais para enviar publicidade para você, bem
            como outros materiais promocionais voltados ao marketing de nossos serviços e de
            Parceiros Comerciais do iFood, bem como outras novidades, o que inclui marketing
            direcionado em redes sociais, buscadores, mídias e notificações push.
            {' '}
          </p>
          <InfoBlock className="five-seven-left-text-block">
            <IllustrationPhone />
            <p className="text-grey-2 text-sm">
              <strong>Você pode optar por não mais receber notificações de publicidade,</strong> a qualquer momento, alterando as suas
              preferências pela Plataforma, na aba “Gerenciar
              notificações” em “Configurações”.
            </p>
          </InfoBlock>
        </div>
        <div className="column why-collect-your-data__chapter-five-seven--right">
          <IllustrationFiveSeven />
        </div>
      </div>
      <div className="columns why-collect-your-data__chapter-five-eight">
        <div className="column why-collect-your-data__chapter-five-eight--left">
          <IllustrationFiveEight />
        </div>
        <div className="column why-collect-your-data__chapter-five-eight--right">
          <SubSectionTitle>5.8. Para realizar promoções</SubSectionTitle>
          <p className="text-grey-2">
            Se você participar de um concurso, competição ou qualquer outra modalidade de promoção,{' '}
            <strong>
              poderemos usar as informações que você fornecer para administrar esses programas.
            </strong>
          </p>
        </div>
      </div>
      <div className="columns why-collect-your-data__chapter-five-nine">
        <div className="column why-collect-your-data__chapter-five-nine--left">
          <SubSectionTitle>5.9. Para promover pesquisas diversas</SubSectionTitle>
          <p className="text-grey-2">
            Podemos utilizar seus dados para entrar em contato diretamente com você e coletar a sua
            opinião para aprimoramento de nossos serviços.
          </p>
          <p className="text-grey-2">
            Nesta hipótese,{' '}
            <strong>
              perguntaremos se você concorda em participar da pesquisa em questão, sempre podendo
              recusar.
            </strong>
          </p>
        </div>
        <div className="column why-collect-your-data__chapter-five-nine--right">
          <IllustrationFiveNine />
        </div>
      </div>
      <div className="columns why-collect-your-data__chapter-five-ten">
        <div className="column why-collect-your-data__chapter-five-ten--left">
          <IllustrationFiveTen />
        </div>
        <div className="column why-collect-your-data__chapter-five-ten--right">
          <SubSectionTitle>5.10 Em caso de requisições legais e regulatórias</SubSectionTitle>
          <p className="text-grey-2">
            Seus dados poderão ser utilizados para endereçarmos reivindicações jurídicas e
            regulatórias relacionadas à utilização dos nossos serviços.
          </p>
        </div>
      </div>
    </div>
  </section>
)

export default WhyCollectYourData
