import React from 'react'
import IlustraIfoodPeople from 'assets/svg/ilustra-ifood-people.svg'
import SectionTitle from '../../components/SectionTitle'

import './styles.scss'
import BlockText from './BlockText'

const WhoThisDeclarationApply = () => (
  <section id="capitulo-1" className="declaration-apply">
    <div className="container">
      <div className="columns is-vcentered">
        <div className="column is-5">
          <SectionTitle>1. A quem esta Declaração se aplica?</SectionTitle>
          <p className="text-xl font-normal">
            Esta <strong>Declaração de Privacidade,</strong> também chamada de “Declaração”,{' '}
            <strong>se aplica às pessoas que:</strong>
          </p>
        </div>
        <div className="column">
          <IlustraIfoodPeople />
        </div>
      </div>
      <div className="declaration-apply__blocks-text">
        <BlockText text="acessarem" />
        <BlockText text="se cadastrarem em nossa Plataforma" />
        <BlockText text="ou de qualquer outra forma utilizarem os produtos do iFood." />
      </div>
      <div className="declaration-apply__bottom-text">
        <h3 className="text-grey-2">
          Nosso aplicativo e site serão denominados, em conjunto, como “Plataforma” ou
          “Plataformas”.
        </h3>
      </div>
    </div>
  </section>
)

export default WhoThisDeclarationApply
