import React from 'react'
import GlobeIllustration from 'assets/svg/data-mundo.svg'
import SectionTitle from '../../components/SectionTitle'

import './styles.scss'

const DataTransferToOtherCountries = () => (
  <section className="transfer-countries" id="capitulo-10">
    <div className="container">
      <div className="columns is-vcentered">
        <div className="column">
          <SectionTitle>10. O iFood transfere os dados para outros países?</SectionTitle>
          <p>
            Para realizar algumas das atividades envolvidas nos serviços da Plataforma,{' '}
            <strong>
              o iFood poderá fazer transferências internacionais de dados para outros países
            </strong>
            , tais como Estados Unidos da América e para países da União Europeia e da América
            Latina.
          </p>
          <p>
            Em <strong>qualquer caso de compartilhamento</strong> com parceiros ou prestadores de
            serviços localizados em outros países, estabelecemos contratualmente que{' '}
            <strong>
              o parceiro deve possuir um padrão de proteção de dados e segurança da informação
              compatível
            </strong>{' '}
            com esta Declaração e com a legislação aplicável.
          </p>
        </div>
        <div className="column">
          <GlobeIllustration />
        </div>
      </div>
    </div>
  </section>
)

export default DataTransferToOtherCountries
