import React from 'react'
import Illustration from 'assets/svg/illustration-chapter-4.svg'
import ExclamationMark from 'assets/svg/illustration-exclamacao.svg'
import IllustrationData from 'assets/svg/esquema.svg'
import IllustrationSubChapterC from 'assets/svg/illustra-4-2-c.svg'
import IllustrationChapterFour from 'assets/svg/illustration-chapter-4-3.svg'
import IllustrationChapterFourMobile from 'assets/svg/circles.svg'
import useWindowSize from 'hooks/useWindowSize'
import SectionTitle from '../../components/SectionTitle'
import SubSectionTitle from '../../components/SubSectionTitle'
import InfoBlock from '../../components/InfoBlock'
import CardText from '../../components/CardText'

import './styles.scss'

const WhatDataIsCollected = () => {
  const { isMobileDevice } = useWindowSize()
  return (
    <section id="capitulo-4" className="what-data-collected">
      <div className="what-data-collected__subsection-grey">
        <div className="container">
          <div className="columns subsection-grey-chapter-four">
            <div className="column column-left">
              <SectionTitle>4. Quais dados são coletados?</SectionTitle>
              <p className="text-xl text-grey-2 font-normal">
                Ao criar uma conta no iFood, nós poderemos coletar informações importantes sobre
                você.
              </p>
              <p className="text-xl text-grey-2 font-normal">
                Abaixo, detalhamos quais são essas informações.
              </p>
            </div>
            <div className="column column-right">
              <Illustration />
            </div>
          </div>
          <div className="subsection-grey-chapter-four-one">
            <div className="columns subsection-grey-chapter-four-one__a">
              <div className="column">
                <SubSectionTitle>4.1 Dados que você nos fornece</SubSectionTitle>
                <p className="text-xl font-normal text-black">a) Dados de seu perfil</p>
                <p className="text-xl font-normal text-grey-2">Esses dados incluem:</p>
                <div className="cards-list">
                  <CardText text="seu nome" />
                  <CardText text="CPF (quando aplicável)" />
                  <CardText text="e-mail" />
                  <CardText text="endereço de entrega" />
                  <CardText text="número de telefone e preferências de contato" />
                </div>
              </div>
            </div>
            <div className="columns">
              <div className="column">
                <p className="text-grey-2">
                  Para criar o seu cadastro na Plataforma do iFood, você tem a opção de usar a sua
                  conta de redes sociais. Por exemplo, ao utilizar o Facebook para se inscrever no
                  nosso Serviço, você estará permitindo que o iFood acesse as informações pessoais
                  em sua conta do Facebook, tais como seu nome e e-mail (caso os tenha cadastrado no
                  Facebook).
                </p>
                <p className="text-grey-2">
                  As informações que iremos obter, nesse caso,{' '}
                  <strong>
                    são as mesmas necessárias para o uso da Plataforma e dependem das suas
                    configurações de privacidade junto ao serviço de rede social.
                  </strong>
                </p>
              </div>
              <div className="column is-flex is-justify-content-flex-end is-align-items-flex-start">
                <InfoBlock>
                  <ExclamationMark />
                  <p>
                    Informamos que comunicar o CPF é aplicável somente quando a sua indicação for
                    necessária para certos estabelecimentos com finalidade fiscal.
                  </p>
                </InfoBlock>
              </div>
            </div>
            <div className="columns subsection-grey-chapter-four-one__b">
              <div className="column column-left">
                <p className="text-xl font-normal text-black">b) Dados de pagamento</p>
                <p className="text-grey-2">
                  Ao fazer o seu pedido pelo iFood, é possível que, a depender do restaurante
                  escolhido, você possa fazer o pagamento diretamente por nossas Plataformas.
                </p>
                <p className="text-grey-2">
                  Ao escolher fazer o pagamento direto na Plataforma, você poderá nos fornecer os
                  seus dados de pagamento, tais como aqueles de cartão de crédito e de meios de
                  pagamento parceiros.
                </p>
              </div>
              <div className="column column-right is-flex is-justify-content-flex-end is-align-items-flex-start">
                <InfoBlock className="text-block-chapter-four-one-b">
                  <p className="text-white">Atenção</p>
                  <p className="text-white">
                    Suas informações de pagamento online são armazenadas somente de forma mascarada
                    pelo iFood. Assim, os dados completos de seus cartões não ficam armazenados em
                    nossas bases e suas transações são feitas com o mais alto padrão de segurança.
                  </p>
                </InfoBlock>
              </div>
            </div>
            <div className="columns">
              <div className="column">
                <p className="text-xl font-normal text-black">c) Dados de localização</p>
                <p className="text-grey-2">
                  Para realizarmos a entrega do seu pedido,{' '}
                  <strong>
                    nós precisamos que você também nos informe a sua localização ou o endereço do
                    local onde gostaria que entregássemos o seu pedido.
                  </strong>
                </p>
                <p className="text-grey-2">
                  Essa localização pode ser fornecida pelo endereço que você inserir manualmente no
                  aplicativo, ou através da localização obtida do seu dispositivo via GPS e redes
                  móveis (torres de celular, Wi-Fi e outras modalidades de localização) e confirmada
                  por você.
                </p>
              </div>
              <div className="column is-flex is-justify-content-flex-end is-align-items-center">
                <InfoBlock>
                  <ExclamationMark />
                  <p>
                    Para fins da{' '}
                    <strong className="text-primary">
                      Lei n° 12.965 de 2014 (Marco Civil da Internet)
                    </strong>
                    , ou qualquer lei que venha substituí-la, a localização fornecida será
                    considerada como dado cadastral.
                  </p>
                </InfoBlock>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="what-data-collected__subsection-white">
        <div className="container">
          <div className="column subsection-white-chapter-four-two-a">
            <SubSectionTitle>4.2 Dados gerados durante a utilização dos serviços</SubSectionTitle>
            <p className="text-xl font-normal text-black subtitle">
              a) Dados dos seus dispositivos
            </p>
            <p className="text-grey-2">
              <strong>Nós podemos,</strong> automaticamente, coletar informações sobre os
              dispositivos a partir dos quais você acessa o iFood, como:
            </p>
            {isMobileDevice ? <IllustrationChapterFourMobile /> : <IllustrationData />}
            <p className="text-xl font-normal text-black">
              b) Dados transacionais e dados sobre sua utilização
            </p>
            <p className="text-grey-2">
              Coletamos dados sobre suas interações em nossa Plataforma, incluindo:
            </p>
            <div className="subsection-white-chapter-four-two-a__cards-list">
              <CardText text="data e horário de acessos" />
              <CardText text="buscas" />
              <CardText text="visualizações" />
            </div>
            <p className="text-grey-2">
              Também podemos coletar dados transacionais relacionados aos uso dos nossos serviços
              como:
            </p>
            <div className="subsection-white-chapter-four-two-a__cards-list">
              <CardText text="detalhes do pedido" />
              <CardText text="data e hora do pedido" />
              <CardText text="valor cobrado" />
              <CardText text="distância entre o estabelecimento e local de entrega" />
              <CardText text="método de pagamento" />
            </div>
          </div>
          <div className="columns subsection-white-chapter-four-two-c">
            <div className="column">
              <p className="text-xl font-normal text-black subtitle">c) Dados de comunicação</p>
              <p className="text-grey-2">
                Intermediamos a comunicação entre usuários, entregadores e estabelecimentos
                parceiros através da nossa Plataforma. Permitimos, por exemplo, que usuários,
                entregadores e estabelecimentos parceiros, enviem mensagens através do chat ou de
                telefone anonimizados.
              </p>
              <InfoBlock>
                <ExclamationMark />
                <p>
                  Para prestar este serviço, o iFood pode receber alguns dados relativos às
                  chamadas, textos ou outras comunicações, incluindo a data e hora das comunicações
                  e o seu conteúdo.
                </p>
              </InfoBlock>
            </div>
            <div className="column">
              <IllustrationSubChapterC className="illutration-chapter-c" />
            </div>
          </div>
          <div className="columns subsection-white-chapter-four-three">
            <div className="column column-left">
              <IllustrationChapterFour />
            </div>
            <div className="column column-right">
              <SubSectionTitle>4.3 Dados não-pessoais</SubSectionTitle>
              <p className="text-grey-2">
                Nós também podemos obter informações não-pessoais, ou seja, dados que não permitem a
                associação direta com qualquer pessoa especificamente. É um exemplo de dado
                não-pessoal o agrupamento de pedidos por região.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default WhatDataIsCollected
