import background from 'assets/images/privacy/banner-privacy.png'
import CookiesInfo from './CookiesInfo'
import DataTransferToOtherCountries from './DataTransferToOtherCountries'
import ExerciseUserRights from './ExerciseUserRights'
import HowDataIsShared from './HowDataIsShared'
import HowDataIsStored from './HowDataIsStored'
import NotifyingDeclarationChanges from './NotifyingDeclarationChanges'
import ProcessingPersonalData from './ProcessingPersonalData'
import ProtectingYourData from './ProtectingYourData'
import UserRights from './UserRights'
import WhatDataIsCollected from './WhatDataIsCollected'
import WhoControlsTreatment from './WhoControlsTreatment'
import WhoThisDeclarationApply from './WhoThisDeclarationApply'
import WhyCollectYourData from './WhyCollectYourData'

// eslint-disable-next-line
export const data = {
  listButtonsLeft: [
    {
      textButton: '1. A quem esta Declaração se aplica?',
      href: 'capitulo-1',
      content: WhoThisDeclarationApply,
    },
    {
      textButton: '2. O que é o tratamento de dados pessoais?',
      href: 'capitulo-2',
    },
    {
      textButton: '3. Quem controla o tratamento?',
      href: 'capitulo-3',
    },
    {
      textButton: '4. Quais dados são coletados?',
      href: 'capitulo-4',
    },
    {
      textButton: '5. Por que coletamos os seus dados?',
      href: 'capitulo-5',
    },
    {
      textButton: '6. Como protegemos os seus dados?',
      href: 'capitulo-6',
    },
    {
      textButton: '7. Como os dados são armazenados?',
      href: 'capitulo-7',
    },
  ],
  listButtonsRight: [
    {
      textButton: '8. Como os dados são compartilhados?',
      href: 'capitulo-8',
    },
    {
      textButton: '9. Como utilizamos cookies e outras tecnologias?',
      href: 'capitulo-9',
    },
    {
      textButton: '10. O iFood transfere os dados para outros países?',
      href: 'capitulo-10',
    },
    {
      textButton: '11. Quais são os seus direitos?',
      href: 'capitulo-11',
    },
    {
      textButton: '12. Como exercer os seus direitos?',
      href: 'capitulo-12',
    },
    {
      textButton: '13. Como notificamos as mudanças feitas nesta Declaração?',
      href: 'capitulo-13',
    },
  ],
  listMobileContent: [
    {
      textButton: '1. A quem esta Declaração se aplica?',
      content: WhoThisDeclarationApply,
    },
    {
      textButton: '2. O que é o tratamento de dados pessoais?',
      content: ProcessingPersonalData,
    },
    {
      textButton: '3. Quem controla o tratamento?',
      content: WhoControlsTreatment,
    },
    {
      textButton: '4. Quais dados são coletados?',
      content: WhatDataIsCollected,
    },
    {
      textButton: '5. Por que coletamos os seus dados?',
      content: WhyCollectYourData,
    },
    {
      textButton: '6. Como protegemos os seus dados?',
      content: ProtectingYourData,
    },
    {
      textButton: '7. Como os dados são armazenados?',
      content: HowDataIsStored,
    },
    {
      textButton: '8. Como os dados são compartilhados?',
      content: HowDataIsShared,
    },
    {
      textButton: '9. Como utilizamos cookies e outras tecnologias?',
      content: CookiesInfo,
    },
    {
      textButton: '10. O iFood transfere os dados para outros países?',
      content: DataTransferToOtherCountries,
    },
    {
      textButton: '11. Quais são os seus direitos?',
      content: UserRights,
    },
    {
      textButton: '12. Como exercer os seus direitos?',
      content: ExerciseUserRights,
    },
    {
      textButton: '13. Como notificamos as mudanças feitas nesta Declaração?',
      content: NotifyingDeclarationChanges,
    },
  ],
  dataAtualization: '18 de agosto de 2022',
  lastVersions: {
    label: 'Acesse aqui',
    options: [
      {
        label: { title: 'Versão 4', description: '(09/04/2021 até 17/08/2022)' },
        value: '/abrindo-a-cozinha/privacidade-cliente-versao4/',
      },
      {
        label: { title: 'Versão 3', description: '(15/09/2020 até 08/04/2021)' },
        value: '/abrindo-a-cozinha/privacidade-cliente-versao3/',
      },
      {
        label: { title: 'Versão 2', description: '(17/07/2018 até 14/09/2020)' },
        value: '/abrindo-a-cozinha/privacidade-cliente-versao2/',
      },
      {
        label: { title: 'Versão 1', description: '(até 16/07/2018)' },
        value: '/abrindo-a-cozinha/privacidade-cliente-versao1/',
      },
    ],
  },
  timeToRead: '34 minutos',
  backgroundImage: background,
  typeUser: 'Usuários',
}
