import React from 'react'
// import { navigate } from 'gatsby';
import SEO from 'components/SEO'
import Layout from 'components/Layout'
import HeroBanner from 'components/Sections/Privacy/components/HeroBanner'
import ProgressBar from 'components/ProgressBar'
import ProtectingYourData from 'components/Sections/Privacy/PrivacyUsers/ProtectingYourData'
import IntroductionSection from 'components/Sections/Privacy/PrivacyUsers/IntroductionSection'
import ChooseChapter from 'components/Sections/Privacy/ChooseChapter'
import WhoThisDeclarationApply from 'components/Sections/Privacy/PrivacyUsers/WhoThisDeclarationApply'
import ProcessingPersonalData from 'components/Sections/Privacy/PrivacyUsers/ProcessingPersonalData'
import WhoControlsTreatment from 'components/Sections/Privacy/PrivacyUsers/WhoControlsTreatment'
import WhatDataIsCollected from 'components/Sections/Privacy/PrivacyUsers/WhatDataIsCollected'
import HowDataIsStored from 'components/Sections/Privacy/PrivacyUsers/HowDataIsStored'
import HowDataIsShared from 'components/Sections/Privacy/PrivacyUsers/HowDataIsShared'
import DataTransferToOtherCountries from 'components/Sections/Privacy/PrivacyUsers/DataTransferToOtherCountries'
import Chapters from 'components/Sections/Privacy/components/Chapters'
import UserRights from 'components/Sections/Privacy/PrivacyUsers/UserRights'
import ExerciseUserRights from 'components/Sections/Privacy/PrivacyUsers/ExerciseUserRights'
import NotifyingDeclarationChanges from 'components/Sections/Privacy/PrivacyUsers/NotifyingDeclarationChanges'
import CookiesInfo from 'components/Sections/Privacy/PrivacyUsers/CookiesInfo'
import WhyCollectYourData from 'components/Sections/Privacy/PrivacyUsers/WhyCollectYourData'
import { data } from 'components/Sections/Privacy/PrivacyUsers/helpers'

const Privacidade = () => {
  // React.useEffect(() => {
  //   navigate('/privacidade/');
  // }, []);

  //   return null;

  return (
    <Layout>
      <SEO description="Conheça nossa Política de privacidade" title="Privacidade" />
      <HeroBanner className="users-privacy__hero-banner" data={data} />
      <ProgressBar />
      <IntroductionSection />
      <ChooseChapter data={data} />
      <Chapters>
        <WhoThisDeclarationApply />
        <ProcessingPersonalData />
        <WhoControlsTreatment />
        <WhatDataIsCollected />
        <WhyCollectYourData />
        <ProtectingYourData />
        <HowDataIsStored />
        <HowDataIsShared />
        <CookiesInfo />
        <DataTransferToOtherCountries />
        <UserRights />
        <ExerciseUserRights />
        <NotifyingDeclarationChanges />
      </Chapters>
    </Layout>
  )
}

export default Privacidade
