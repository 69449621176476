import React from 'react'
import ExclamationMark from 'assets/svg/illustration-exclamacao.svg'
import ComputerData from 'assets/svg/computador-dados.svg'
import ComputerDataMobile from 'assets/svg/computador-dados-mobile.svg'
import IllustrationTime from 'assets/svg/illustration-time.svg'
import InfoDots from 'assets/svg/exclusion-info.svg'
import InfoDotsMobile from 'assets/svg/exclusion-info-mobile.svg'
import useWindowSize from 'hooks/useWindowSize'

import SectionTitle from '../../components/SectionTitle'
import SubSectionTitle from '../../components/SubSectionTitle'
import InfoBlock from '../../components/InfoBlock'

import './styles.scss'

const HowDataIsStored = () => {
  const { isMobileDevice } = useWindowSize()
  return (
    <section className="data-stored pb-16" id="capitulo-7">
      <header className="data-stored__header">
        {isMobileDevice ? (
          <ComputerDataMobile />
        ) : (
          <ComputerData className="header__illustration" />
        )}
        <div className="header__text">
          <SectionTitle>7. Como os dados são armazenados?</SectionTitle>
          <p className="text-xl font-normal text-center">
            Saiba onde e por quanto tempo armazenamos os seus dados.
          </p>
        </div>
      </header>
      <div className="container">
        <div className="columns data-stored__subsections">
          <div className="column is-6-desktop-only">
            <div>
              <SubSectionTitle>7.1 Onde os dados são armazenados?</SubSectionTitle>
              <p>
                <span className="text-primary font-bold">
                  Os dados que coletamos de você são armazenados em serviços de nuvem confiáveis,
                </span>{' '}
                de parceiros que podem estar localizados no Brasil ou em outros países que ofereçam
                serviço de armazenamento em nuvem confiáveis e usualmente utilizados por empresas de
                tecnologia, tais como Estados Unidos da América (EUA) e em países da América Latina
                e da Europa.
              </p>
            </div>
            <InfoBlock>
              <ExclamationMark />
              <p>
                Na contratação desses serviços, o iFood sempre busca empresas que empregam{' '}
                <strong>alto nível de segurança no armazenamento de suas informações,</strong>{' '}
                estabelecendo contratos <strong>que não violam as definições de privacidade</strong>{' '}
                previstas nesta <strong>Declaração e na legislação aplicável.</strong>
              </p>
            </InfoBlock>
          </div>
          <div className="column is-6-desktop-only">
            <div>
              <SubSectionTitle>7.2. Por quanto tempo os dados são armazenados</SubSectionTitle>
              <p>
                O iFood armazena as suas informações durante o período necessário para as
                finalidades apresentadas nos
                <span className="text-primary font-bold">Termos e Condições de Uso do iFood</span> e
                nesta Declaração de Privacidade, respeitando o período de retenção de dados
                determinado pela legislação aplicável.
              </p>
            </div>
            <InfoBlock>
              <ExclamationMark />
              <p>
                Caso você solicite a exclusão de sua conta, os seus dados pessoais fornecidos ao
                iFood durante a sua utilização dos nossos serviços serão excluídos, salvo para
                cumprir finalidades permitidas pela{' '}
                <strong>legislação de proteção de dados.</strong>
              </p>
            </InfoBlock>
          </div>
        </div>
      </div>
      <div className="data-stored__exclusion-info">
        {isMobileDevice ? (
          <InfoDotsMobile />
        ) : (
          <>
            <IllustrationTime className="illustration-info" />
            <InfoDots className="illustration-text" />
          </>
        )}
      </div>
    </section>
  )
}

export default HowDataIsStored
