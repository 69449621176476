import React from 'react'
import PropTypes from 'prop-types'
import './styles.scss'

const BlockText = ({ text }) => (
  <div className="block-text-container">
    <p>{text}</p>
  </div>
)
BlockText.propTypes = {
  text: PropTypes.string.isRequired,
}

export default BlockText
