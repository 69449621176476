import React from 'react'
import Collect from 'assets/svg/coleta.svg'
import Use from 'assets/svg/utilizacao.svg'
import Access from 'assets/svg/acesso.svg'
import Processing from 'assets/svg/processamento.svg'
import ProcessingTwo from 'assets/svg/processamento2.svg'
import Elimination from 'assets/svg/eliminacao.svg'
import Comunication from 'assets/svg/comunicacao.svg'
import Transfer from 'assets/svg/transferencia.svg'
import SectionTitle from '../../components/SectionTitle'

import './styles.scss'
import InfoBlock from '../../components/InfoBlock'

const ProcessingPersonalData = () => (
    <section id="capitulo-2" className="processing-personal-data">
      <div className="container">
        <div className="processing-personal-data__row-top">
          <SectionTitle>2. O que é o tratamento de dados pessoais?</SectionTitle>
          <h3 className="text-2xl text-grey-2">
            O tratamento de dados pessoais (ou tratar dados), significa{' '}
            <strong>qualquer operação realizada com esses dados.</strong>
          </h3>
          <h3 className="text-lg text-dark-orange">Por exemplo:</h3>
        </div>
        <div className="processing-personal-data__row-bottom">
          <div className="row-bottom-operations">
            <div className="orange-ellipse">
              <Collect />
            </div>
            <p>Coleta</p>
          </div>
          <div className="row-bottom-operations">
            <div className="orange-ellipse">
              <Use />
            </div>
            <p>Utilização</p>
          </div>
          <div className="row-bottom-operations">
            <div className="orange-ellipse">
              <Access />
            </div>
            <p>Acesso</p>
          </div>
          <div className="row-bottom-operations">
            <div className="orange-ellipse">
              <Processing />
            </div>
            <p>Processamento</p>
          </div>
          <div className="row-bottom-operations">
            <div className="orange-ellipse">
              <ProcessingTwo />
            </div>
            <p>Armazenamento</p>
          </div>
          <div className="row-bottom-operations">
            <div className="orange-ellipse">
              <Elimination />
            </div>
            <p>Eliminação</p>
          </div>
          <div className="row-bottom-operations">
            <div className="orange-ellipse">
              <Comunication />
            </div>
            <p>Comunicação</p>
          </div>
          <div className="row-bottom-operations">
            <div className="orange-ellipse">
              <Transfer />
            </div>
            <p>Transferência</p>
          </div>
        </div>
        <InfoBlock className="processing-personal-data__info-block-bottom">
          <p>
            O dado pessoal é uma informação relacionada a uma pessoa humana identificada ou
            identificável. O dado relativo a você, cliente do iFood,{' '}
            <strong>é um dado pessoal.</strong>
          </p>
        </InfoBlock>
      </div>
    </section>
  )

export default ProcessingPersonalData
