import React from 'react'
import GatsbyImage from 'gatsby-image/index'
import { graphql, useStaticQuery } from 'gatsby'
import useWindowSize from 'hooks/useWindowSize'

import SectionTitle from '../../components/SectionTitle'

import './styles.scss'

const ExerciseUserRights = () => {
  const { isMobileDevice } = useWindowSize()
  const { screenSteps, initialPage, menuProfile, menuHelp, menuAccount } = useStaticQuery(graphql`
    query {
      screenSteps: file(relativePath: { eq: "privacy/telas-passos.png" }) {
        childImageSharp {
          fluid(maxWidth: 1400, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      initialPage: file(relativePath: { eq: "privacy/Privacidade-Inicial.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      menuProfile: file(relativePath: { eq: "privacy/Privacidade-Perfil.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      menuHelp: file(relativePath: { eq: "privacy/Privacidade-Ajuda.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      menuAccount: file(relativePath: { eq: "privacy/Privacidade-Conta.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <section className="exercise-rights" id="capitulo-12">
      <div className="container">
        <SectionTitle>12. Como exercer os seus direitos?</SectionTitle>
        <div className="columns">
          <div className="column">
            <p>
              Para que você exerça <strong>seus direitos</strong> enquanto titular de dados
              pessoais, o iFood disponibiliza meios específicos para as requisições através da{' '}
              <strong>aba “Ajuda”</strong> em nossa Plataforma.{' '}
            </p>
            <p>
              Você pode clicar no menu inferior “Perfil”, depois em “Ajuda” e, em seguida,
              “Conta” e encontrará respostas e direcionamentos específicos para sua
              requisição.
            </p>
          </div>
          <div className="column">
            <p>
              Se você tiver algum questionamento ou dúvida com relação a esta Declaração de
              Privacidade do iFood ou qualquer prática aqui descrita,{' '}
              <strong>
                você também poderá entrar em contato conosco através da nossa página de “Ajuda” na
                Plataforma.
              </strong>
            </p>
          </div>
        </div>
      </div>
      {isMobileDevice ? (
        <div className="exercise-rights__image-steps">
          <GatsbyImage
            alt="Clique no Menu inferior “Perfil”"
            fluid={initialPage.childImageSharp.fluid}
            className="image-step"
          />
          <GatsbyImage
            alt="Descendo a tela, terá o link Ajuda"
            fluid={menuProfile.childImageSharp.fluid}
            className="image-step"
          />
          <GatsbyImage
            alt="Descendo a tela, terá o link Conta"
            fluid={menuHelp.childImageSharp.fluid}
            className="image-step"
          />
          <GatsbyImage
            alt="Encontre respostas e direcionamentos específicos para sua requisição"
            fluid={menuAccount.childImageSharp.fluid}
            className="image-step"
          />
        </div>
      ) : (
        <GatsbyImage
          alt="Passos para falar com o iFood"
          fluid={screenSteps.childImageSharp.fluid}
        />
      )}
    </section>
  )
}

export default ExerciseUserRights
