import React from 'react'
import CardsWithText from './CardsWithText'
import './styles.scss'

const IntroductionSection = () => (
    <section className="introduction-section">
      <div className="container">
        <div className="columns">
          <div className="column introduction-section__container-left">
            <h1 className="text-primary text-7xl">Seus dados são seus.</h1>
            <div>
              <p className="text-lg">
                A proteção dos seus dados é muito importante para o iFood. Respeitamos a sua
                privacidade em nossa missão de proporcionar um serviço cada vez melhor!
              </p>
              <p className="text-lg">
                Essa <b>Declaração de Privacidade</b> descreve como obtemos, armazenamos,
                utilizamos, compartilhamos e<b> protegemos</b> as suas informações.
              </p>
              <p className="text-lg">
                <strong>Recomendamos que você conheça as nossas práticas como um todo</strong>, mas
                aqui estão alguns pontos importantes para que você entenda como seus dados são
                tratados pelo iFood:
              </p>
            </div>
          </div>
          <div className="column introduction-section__container-right">
            <div className="columns column-cards">
              <div className="column column-cards__left">
                <CardsWithText>
                  <p>
                    <b className="text-primary">•</b> Os dados que você cadastra em nossas
                    plataformas
                    <strong> são tratados de forma segura.</strong>
                  </p>
                </CardsWithText>
                <CardsWithText>
                  <p>
                    <b className="text-primary">•</b> Os dados completos de seus cartões{' '}
                    <strong>não ficam armazenados em nossas bases</strong> e suas transações são
                    feitas com o mais alto padrão de segurança.
                  </p>
                </CardsWithText>
                <CardsWithText>
                  <p>
                    <b className="text-primary">•</b> Você poderá tirar suas dúvidas e solicitar
                    informações a qualquer momento através da aba Ajuda em nossas plataformas.
                  </p>
                </CardsWithText>
              </div>
              <div className="column column-cards__right">
                <CardsWithText>
                  <p>
                    <b className="text-primary">•</b> <strong>Limitamos o tratamento</strong> dos
                    dados para as suas devidas finalidades e em{' '}
                    <strong>conformidade com a lei.</strong>
                  </p>
                </CardsWithText>
                <CardsWithText>
                  <p>
                    <b className="text-primary">•</b> Você pode alterar suas preferências de
                    comunicação no próprio aplicativo,
                    <strong>
                      {' '}
                      mas ainda poderemos contatá-lo para informações sobre o seu pedido.
                    </strong>
                  </p>
                </CardsWithText>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )

export default IntroductionSection
