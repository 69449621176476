import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import GatsbyImage from 'gatsby-image'
import useWindowSize from 'hooks/useWindowSize'

import CookiesComputer from 'assets/svg/cookies-computador.svg'
import IllustrationShopper from 'assets/svg/bag-alerta.svg'
import UserInfoText from 'assets/svg/informacao-usuario-text.svg'
import IllustrationGlass from 'assets/svg/lupa-telefone.svg'
import PublicityTimeline from 'assets/svg/timeline-text-communication.svg'
import PublicityTimelineMobile from 'assets/svg/timeline-text-communication-mobile.svg'
import CommunicationText from 'assets/svg/comunicacao-texto.svg'
import SectionTitle from '../../components/SectionTitle'
import SubSectionTitle from '../../components/SubSectionTitle'
import InfoBlock from '../../components/InfoBlock'

import './styles.scss'

const CookiesInfo = () => {
  const { isMobileDevice } = useWindowSize()
  const { thirdPartyTechnologies } = useStaticQuery(graphql`
    query {
      thirdPartyTechnologies: file(relativePath: { eq: "privacy/ifood-ilustra-techteam.png" }) {
        childImageSharp {
          fluid(maxWidth: 1400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <section className="user-cookies" id="capitulo-9">
      <header>
        <div className="container">
          <div className="columns is-vcentered">
            <div className="column">
              <SectionTitle>9. Como utilizamos cookies e outras tecnologias?</SectionTitle>
              <p>
                O iFood pode utilizar, para uma variedade de funções, tecnologias como{' '}
                <b>cookies, pixel tags, armazenamento local ou outros identificadores</b>, tanto de
                dispositivos móveis ou não, e/ou tecnologias semelhantes (“cookies e outras
                tecnologias”), para uma variedade de funções. Essas tecnologias nos ajudam{' '}
                <strong>
                  a autenticar a sua conta, promover e aperfeiçoar os serviços do iFood,
                  personalizar a sua experiência e avaliar a eficácia da nossa comunicação e
                  publicidade.
                </strong>
              </p>
            </div>
            <div className="column is-flex is-justify-content-center">
              <CookiesComputer />
            </div>
          </div>
        </div>
      </header>
      <div className="user-cookies__technologies">
        <div className="container">
          <div className="columns">
            <div className="column">
              <SubSectionTitle>9.1. O que são essas tecnologias?</SubSectionTitle>
            </div>
          </div>
          <div className="columns">
            <div className="column">
              <h4>PIXEL TAGS</h4>
              <small>(ou GIFs limpos, web beacons ou pixels)</small>
              <p>
                são pequenos blocos de código em uma página da web que permitem que elas realizem
                ações como <b>ler e armazenar cookies e transmitir informações para o iFood</b>. A
                conexão resultante pode incluir informações como o endereço de IP de um dispositivo,
                a hora em que uma pessoa visualizou o pixel, um identificador associado ao navegador
                ou dispositivo e o tipo de navegador em uso.
              </p>
            </div>
            <div className="column">
              <h4>IDENTIFICADORES DE DISPOSITIVO MOBILE</h4>
              <p>
                são códigos que possibilitam a identificação do seu dispositivo móvel, seja de
                maneira persistente ou transitória, tais como o ID de Publicidade ou o ID de seu
                sistema operacional.
              </p>
              <h4>COOKIES</h4>
              <p>
                são pequenos arquivos armazenados no seu navegador, celular ou outro dispositivo.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container user-cookies__communication">
        <div className="columns">
          <div className="column is-two-fifths">
            <IllustrationShopper />
          </div>
          <div className="column">
            <SubSectionTitle>
              9.2. Para oferecer uma
              <br /> experiência personalizada
            </SubSectionTitle>
            <p>
              O iFood e os seus parceiros também usam cookies e outras tecnologias para memorizar
              informações pessoais quando você usa o nosso Site, aplicativo ou a rede de parceiros
              do iFood que possam usar tecnologias similares. A nossa meta, nesses casos, é fazer
              com que a sua experiência com o iFood seja mais conveniente e personalizada.
            </p>
            <InfoBlock className="info-block--communication">
              <p>
                Por exemplo, saber o seu primeiro nome nos permite dar boas-vindas a você na próxima
                vez em que você acessar o iFood.
              </p>
              <p>
                Conhecer seu país e idioma nos permite viabilizar a você uma experiência de compras
                personalizada e mais útil.
              </p>
              <p>
                Saber que você adquiriu determinado produto ou usou um determinado serviço permite
                fazer com que sua publicidade e comunicações de e-mail sejam mais relevantes para os
                seus interesses.
              </p>
            </InfoBlock>
            <p>Nessa linha, podemos também usar essas informações para:</p>
          </div>
        </div>
      </div>
      <div className="user-cookies__user-info">
        <div className="communication-text--mobile">
          <InfoBlock>
            <p>Classificar os usuários dentro da rede iFood e verificar as suas preferências.</p>
          </InfoBlock>
          <InfoBlock>
            <p>
              Identificar os usuários a partir de eventos realizados (como a primeira compra ou o
              download do nosso aplicativo, e a partir de determinadas características de seu perfil
              (como usuários que pedem mais determinada categoria de comida ou que realizam mais de
              4 pedidos por mês).
            </p>
          </InfoBlock>
        </div>
        <UserInfoText />
      </div>
      <div className="user-cookies__publicity">
        <div className="publicity__text">
          <div>
            <SubSectionTitle>
              9.3. Para avaliar a eficácia da nossa comunicação e publicidade
            </SubSectionTitle>
            <p>Também usamos essas informações para:</p>
          </div>
        </div>
        <div className="publicity__illustrations">
          {isMobileDevice ? <PublicityTimelineMobile /> : <PublicityTimeline />}
          <IllustrationGlass />
        </div>
        <div className="communication-text--mobile">
          <InfoBlock>
            <p>
              Em algumas de nossas mensagens de e-mail, nós usamos uma &quot;URL click-through&quot;
              (endereço externo) vinculada ao conteúdo do iFood. Quando os clientes clicam em uma
              dessas URLs, os usuários são enviados para um servidor diferente antes de chegarem à
              página de destino no nosso serviço.
            </p>
          </InfoBlock>
          <InfoBlock>
            <p>
              Nós monitoramos esses dados de click-through para entender o interesse em determinados
              tópicos e avaliar a eficácia das comunicações com os nossos clientes.
            </p>
          </InfoBlock>
          <InfoBlock>
            <p>
              Se você preferir não ser monitorado dessa maneira, não clique em texto ou links
              contidos em mensagens de e-mail enviadas pelo iFood.
            </p>
          </InfoBlock>
        </div>
        <CommunicationText className="communication-text" />
      </div>
      <InfoBlock className="info-block--big">
        <p>
          Os pixel tags nos permitem enviar mensagens de e-mail em formatos que os usuários possam
          ler e nos dizer se o e-mail foi aberto ou não. Nós podemos usar essas informações para
          reduzir ou eliminar as mensagens enviadas aos usuários.
        </p>
      </InfoBlock>
      <div className="container">
        <div className="columns is-vcentered">
          <div className="column">
            <SubSectionTitle>9.4. Tecnologia de terceiros</SubSectionTitle>
            <p>
              Além dos cookies e outras tecnologias do iFood, nós podemos permitir que terceiros
              contratados pelo iFood utilizem cookies e outras tecnologias de sua propriedade para
              identificar seu navegador e dispositivo, de modo a lhe oferecer publicidade
              direcionada do iFood quando você acessa websites ou aplicativos de terceiros.
            </p>
            <p>
              Esses terceiros podem também fornecer ao iFood informações acerca do desempenho das
              campanhas de marketing desenvolvidas por meio do compartilhamento de dados conosco.
            </p>
            <p>
              A título de exemplo, algumas dessas empresas podem utilizar cookies e outras
              tecnologias próprios nos serviços do iFood, tais como:{' '}
              <b className="text-primary ">Facebook, Google Analytics e Double Click.</b>
            </p>
          </div>
          <div className="column third-party__illustration">
            <GatsbyImage
              alt="Ilustração com pessoa com telefone na mão"
              className="illustration__img"
              fluid={thirdPartyTechnologies.childImageSharp.fluid}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default CookiesInfo
